<template>
  <div class="special-product-item">
    <div class="special-product-img">
      <b-link
        :to="{ path: `${productDetailsRouter.path}/${getProductUniqueName}` }"
      >
        <img
          :src="getProductImage"
          class="img-fluid"
          alt=""
        >
      </b-link>
    </div>

    <div class="special-product-text">
      <b-link :to="{ path: `${productDetailsRouter.path}/${getProductUniqueName}` }">
        <h4>{{ getProductDescription }}</h4>
      </b-link>
    </div>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { productDetailsRouter } from '@/router/routePathName'

export default {
  name: 'SpecialProductCard',

  components: {
    BLink,
  },

  props: {
    productId: {
      type: String,
      default: '',
    },
    productUniqueName: {
      type: String,
      default: '',
    },
    productImage: {
      type: String,
      default: '',
    },
    productDescription: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      productDetailsRouter,
    }
  },

  computed: {
    getProductId() {
      return this.productId
    },
    getProductUniqueName() {
      return this.productUniqueName
    },
    getProductImage() {
      return this.productImage
    },
    getProductDescription() {
      return this.productDescription
    },
  },

  methods: {
    redirectProductDetails() {
      this.$router.push({ path: `${productDetailsRouter.path}/${this.getProductUniqueName}` })
    },
  },
}
</script>

<style scoped>
@import './style.scss'
</style>
