<template>
  <section class="special-products">
    <b-container>
      <div class="events-filter">
        <div
          v-if="eventsLoad"
          class="spinner-area"
        >
          <b-spinner />
        </div>

        <ul
          v-if="!eventsLoad"
          class="list-style"
        >
          <li
            v-if="products.length > 0"
            :class="{ active: currentFilter === 'ALL' }"
          >
            <a
              @click="handleGetAllProducts()"
            >
              Todos
            </a>
          </li>
          <li
            v-for="(event, index) in events"
            :key="index"
            :class="{ active: currentFilter === event.id }"
          >
            <a
              @click="handleGetProducts(event.id)"
            >
              {{ event.event_description }}
            </a>
          </li>
        </ul>
      </div>

      <b-row v-if="productsLoad">
        <div
          class="spinner-area"
        >
          <b-spinner />
        </div>
      </b-row>

      <b-row>
        <b-col
          v-for="(product, index) in products"
          :key="index"
          sm="12"
          md="6"
          lg="4"
          class="mb-4"
        >
          <special-product-card
            :product-image="setProductImageUrl(product.image[0].path)"
            :product-description="product.product_description"
            :product-unique-name="product.product_unique_name"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="products.length === 0 && !eventsLoad && !productsLoad"
      >
        <b-col
          cols="12"
          class="text-center"
        >
          Nenhum registro encontrado
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BSpinner,
} from 'bootstrap-vue'
import SpecialProductCard from '@/components/SpecialProductCard/SpecialProductCard.vue'
import { setProductImageUrl, getArrayAttr } from '@/utils/helpers/helpers'
import { getAllEvents } from '@/utils/requests/events'
import { getAllProducts } from '@/utils/requests/products'
import Pagination from '@/components/Pagination/Pagination.vue'
import {findByEvent, findByEventId} from "@/utils/data/products";
import events from "@/utils/data/events";

export default {
  name: 'SpecialProducts',

  components: {
    BContainer,
    BRow,
    BCol,
    BSpinner,
    Pagination,
    SpecialProductCard,
  },

  data() {
    return {
      eventsLoad: false,
      productsLoad: false,

      setProductImageUrl,

      currentFilter: 'ALL',
      events: [],
      products: [],

      search: {
        events: [],
      },
    }
  },

  mounted() {
    this.handleGetEvents()
    this.handleGetProducts()
  },

  methods: {
    handleGetEvents() {
      this.events = events
    },

    async handleGetAllProducts() {
      this.currentFilter = 'ALL'

      this.search.events = []

      await this.handleGetProducts(null)
    },

    handleGetProducts(event) {
      this.products = []

      if(event) {
        this.currentFilter = event
      }

      this.products = findByEvent(event)
    },

    setParams() {
      // return {
      //   perPage: this.paginationData.defaultSize,
      //   page: this.paginationData.currentPage,
      //   isSpecialProducts: 1,
      //   events: this.search.events
      //     ? getArrayAttr(this.search.events, 'id')
      //     : [],
      // }
    },
  },
}
</script>

<style scoped>
@import './style.scss';
</style>
