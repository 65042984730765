const events = [
    {
        "id": "a3ac87b5-46f7-49b4-983f-b1809415b9b3",
        "event_description": "Xmas",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "03327636-30df-41c5-98d9-4983a4e09dde",
        "event_description": "Páscoa",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "d07204b5-d691-4fce-925f-88e7a35980dc",
        "event_description": "Festa Junina",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "daf59067-ed2b-4ef3-a4fb-f4b6e36f8472",
        "event_description": "Batizado",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "6ce5991c-7202-4771-8d5e-a53c9b277164",
        "event_description": "Dia das Mães",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "301614d5-5bb5-453c-9c3c-c4a55a2f9fdb",
        "event_description": "Dia dos Pais",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "83d07a17-de21-4dfc-becf-b3bda7bd0f6b",
        "event_description": "15 Anos",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    }
]

export default events